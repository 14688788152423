import { Module } from 'vuex'
import { INavigationStoreState } from '@/interfaces'

export const NavigationStore: Module<INavigationStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    lastVisitedUrl: undefined
  },

  mutations: {
    navigation (state, url) {
      // console.debug('NavigationStore - mutations - navigation', { url })
      state.lastVisitedUrl = url
    }
  },

  actions: {
    navigate ({ commit }, url: string) {
      // console.debug('NavigationStore - actions - navigate', { url })
      commit('navigation', url)
    }
  }
}
