/* eslint-disable eqeqeq */
import { Module } from 'vuex'
import { IAuthenticationStoreState } from '@/interfaces'
import { auth } from '@/utilities'
import { OAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, User } from 'firebase/auth'
import { usersCollectionDoc } from '@/utilities/firebase-utility'
import { effectScope, toRef } from 'vue'
import { useDocument } from 'vuefire'

// create and export a detached effect scope next to where you create your store
export const scope = effectScope(true)

export const AuthenticationStore: Module<IAuthenticationStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    authUser: undefined,
    isLoading: false,
    user: undefined,
    userListenerCanceller: undefined
  },

  mutations: {
    setAuthUser (state, payload?: User) {
      console.debug('AuthStore - setAuthUser', { state, payload })
      state.authUser = payload != undefined ? payload : undefined
    },
    setLoading (state, value: boolean) {
      console.debug('AuthStore - loading', { value })
      state.isLoading = value
    },
    setUserListenerCanceller (state, userListenerCanceller) {
      console.debug('AuthStore - setUserListenerCanceller', { userListenerCanceller })
      state.userListenerCanceller = userListenerCanceller
    }
  },

  getters: {
    isLoggedIn (state: IAuthenticationStoreState): boolean {
      return state.user !== undefined
    }
  },

  actions: {
    // MARK: - Utilities

    bindAuthUser ({ commit, dispatch }): void {
      console.debug('AuthStore - bindAuthUser')

      onAuthStateChanged(auth, (user: User | null) => {
        console.debug('AuthStore - bindAuthUser - received value', { user })
        if (user && typeof user === 'object') {
          commit('setAuthUser', user)
          dispatch('bindUserRef', user.uid)
        } else {
          commit('setAuthUser')
          dispatch('unbindUserRef')
        }
      })
    },

    // MARK: - Authentication

    async logout ({ commit }) {
      commit('setLoading', true)
      await auth.signOut()
      commit('setLoading', false)
    },

    async emailSignIn ({ commit }, params: { email: string; password: string }) {
      commit('setLoading', true)

      console.debug('AuthStore - emailSignIn')
      try {
        await signInWithEmailAndPassword(auth, params.email, params.password)
      } catch (error) {
        console.error('AuthStore - signIn error', { params, error })
        commit('setLoading', false)
        return false
      }

      commit('setLoading', false)
      return true
    },

    // Authentication actions
    async appleSignIn (
      { commit }
    ): Promise<boolean> {
      commit('setLoading', true)
      console.debug('AuthStore - appleSignIn')
      try {
        const provider = new OAuthProvider('apple.com')
        provider.addScope('name')
        provider.addScope('email')
        console.debug('AuthStore - appleSignIn', { auth, provider })
        const credential = await signInWithPopup(auth, provider)
        console.debug('AuthStore - appleSignIn - credential', { credential })

        commit('setLoading', false)
        return true
      } catch (error) {
        // commit("setError", error.message);
        console.error('AuthStore - appleSignIn error', { error })

        commit('setLoading', false)
        return false
      }
    },

    bindUserRef ({ state, commit }, userId): void {
      console.debug('bindUserRef', { userId })
      scope.run(() => {
        const { stop } = useDocument(usersCollectionDoc(userId), { target: toRef(state, 'user') })
        console.debug('userRef', { stop })
        commit('setUserListenerCanceller', stop)
      })
    },

    unbindUserRef ({ state }): void {
      console.debug('unbindUserRef', { state })
      if (state.userListenerCanceller != undefined) {
        state.userListenerCanceller(true)
      }
    }
  }
}
