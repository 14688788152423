
import { IDocumentModel } from '@boxe-riviera-friulana/shared'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ModalDialog from './ModalDialog.vue'

const usersHelper = createNamespacedHelpers('users')

export default defineComponent({
  name: 'DeleteDocumentDialog',

  emits: ['close', 'confirm'],

  components: { ModalDialog },

  props: {
    modelValue: { type: Object as () => IDocumentModel, required: true }
  },

  methods: {
    ...usersHelper.mapActions(['deleteUserDocument']),

    async onConfirm () {
      console.log('onSave')
      const result = await this.deleteUserDocument({ document: this.modelValue })
      this.$emit('confirm')

      if (result) {
        this.$emit('close')
      }
    }
  }
})
