
import { defineComponent } from 'vue'
import LogoutDialog from '@/components/modal-dialogs/LogoutDialog.vue'
import { createNamespacedHelpers } from 'vuex'
const authHelper = createNamespacedHelpers('auth')

export default defineComponent({
  name: 'MainNavigationBar',

  components: { LogoutDialog },

  data () {
    return {
      items: [
        { path: 'members', name: 'Tesserati', isAvailable: true, isVisible: true },
        { path: 'reports', name: 'Verbali', isAvailable: false, isVisible: false },
        { path: 'calendar', name: 'Calendario', isAvailable: false, isVisible: false }
      ],
      showMenuDropdown: false,
      logoImage: require('@/assets/images/logo.png'),
      showLogoutDialog: false,
      showMobileMenu: false
    }
  },

  computed: {
    ...authHelper.mapState(['user']),
    pagePath: function () { return this.$route.name }
  },

  methods: {
    ...authHelper.mapActions(['logout']),

    onLogout () {
      console.debug('onLogout')
      this.showLogoutDialog = !this.showLogoutDialog
      this.logout()
    }
  }
})
