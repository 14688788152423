
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'

export default defineComponent({
  name: 'SavingReminderDialog',

  emits: ['close', 'action'],

  components: { ModalDialog }
})
