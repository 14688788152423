/* eslint-disable eqeqeq */
import { IUsersStoreState } from '@/interfaces'
import { Module } from 'vuex'
import { uploadDocument, userCommentsCollection, userCommentsCollectionDoc, userDocumentsCollection, userDocumentsCollectionDoc, userDocumentsFolderDocumentFile, userEnrollmentsCollection, userEnrollmentsCollectionDoc, userMedicalCheckUpsCollection, userMedicalCheckUpsCollectionDoc, userPaymentsCollection, userPaymentsCollectionDoc, usersCollectionDoc } from '@/utilities/firebase-utility'
import { DocumentType, ICommentModel, IDocumentModel, IEnrollmentModel, IMedicalCheckUpModel, IPaymentModel, IUserModel, UserRole } from '@boxe-riviera-friulana/shared'
import { algoliaSearch, usersIndex, deleteUserCallable } from '@/utilities'
import { deleteDoc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { v4 as UUID } from 'uuid'
import { User } from 'firebase/auth'

const hitsPerPage = 50

export const UsersStore: Module<IUsersStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    isLoading: false,
    user: undefined,
    users: [],
    userComments: [],
    userDocuments: [],
    userEnrollments: [],
    userMedicalCheckUps: [],
    userPayments: [],
    usersPaginationEnd: false,
    usersSearchQuery: '',
    usersPage: 0,
    usersTotal: 0
  },

  getters: {
    userGenericDocuments (state) {
      return state.userDocuments.filter(d => d.type === DocumentType.GENERIC)
    }
  },

  mutations: {
    setUsersSearchQuery (state, searchQuery) {
      state.usersSearchQuery = searchQuery
    },
    setLoading (state, value: boolean) {
      state.isLoading = value
    },
    setUser (state, user: IUserModel) {
      console.debug('UsersStore - setUser', { user })
      state.user = user
    },
    setUserPayment (state, userPayment: IPaymentModel) {
      console.debug('UsersStore - setUserEnrollment', { userPayment })
      const idx = state.userPayments.findIndex(e => e.id === userPayment.id)
      if (idx === -1) {
        state.userPayments.push(userPayment)
      } else {
        state.userPayments[idx] = userPayment
      }
    },
    setUserPayments (state, userPayments: IPaymentModel[]) {
      console.debug('UsersStore - setUserPayments', { userPayments })
      state.userPayments = userPayments
    },
    setUserComment (state, userComment: ICommentModel) {
      console.debug('UsersStore - setUserEnrollment', { userComment })
      const idx = state.userComments.findIndex(e => e.id === userComment.id)
      if (idx === -1) {
        state.userComments.push(userComment)
      } else {
        state.userComments[idx] = userComment
      }
    },
    setUserComments (state, userComments: ICommentModel[]) {
      console.debug('UsersStore - setUserComments', { userComments })
      state.userComments = userComments
    },
    setUserEnrollment (state, userEnrollment: IEnrollmentModel) {
      console.debug('UsersStore - setUserEnrollment', { userEnrollment })
      const idx = state.userEnrollments.findIndex(e => e.id === userEnrollment.id)
      if (idx === -1) {
        state.userEnrollments.push(userEnrollment)
      } else {
        state.userEnrollments[idx] = userEnrollment
      }
    },
    setUserEnrollments (state, userEnrollments: IEnrollmentModel[]) {
      console.debug('UsersStore - setUserEnrollments', { userEnrollments })
      state.userEnrollments = userEnrollments
    },
    removeUserEnrollment (state, userEnrollment: IEnrollmentModel) {
      console.debug('UsersStore - removeUserEnrollment', { userEnrollment })
      state.userEnrollments = state.userEnrollments.filter(d => d.id !== userEnrollment.id)
      for (const documentId of userEnrollment.documentIds) {
        state.userDocuments = state.userDocuments.filter(d => d.id !== documentId)
      }
    },
    setUserDocument (state, userDocument: IDocumentModel) {
      console.debug('UsersStore - setUserDocuments', { userDocument })
      const idx = state.userDocuments.findIndex(d => d.id === userDocument.id)
      if (idx === -1) {
        state.userDocuments.push(userDocument)
      } else {
        state.userDocuments[idx] = userDocument
      }
    },
    setUserDocuments (state, userDocuments: IDocumentModel[]) {
      console.debug('UsersStore - setUserDocuments', { userDocuments })
      state.userDocuments = userDocuments
    },
    removeUserDocument (state, userDocument: IDocumentModel) {
      console.debug('UsersStore - removeUserDocument', { userDocument })
      state.userDocuments = state.userDocuments.filter(d => d.id !== userDocument.id)
    },
    setUserMedicalCheckUp (state, medicalCheckUp: IMedicalCheckUpModel) {
      console.debug('UsersStore - setUserMedicalCheckUp', { medicalCheckUp })
      const idx = state.userMedicalCheckUps.findIndex(d => d.id === medicalCheckUp.id)
      if (idx === -1) {
        state.userMedicalCheckUps.push(medicalCheckUp)
      } else {
        state.userMedicalCheckUps[idx] = medicalCheckUp
      }
    },
    setUserMedicalCheckUps (state, userMedicalCheckUps: IMedicalCheckUpModel[]) {
      console.debug('UsersStore - setUserMedicalCheckUps', { userMedicalCheckUps })
      state.userMedicalCheckUps = userMedicalCheckUps
    },
    removeUserMedicalCheckUp (state, medicalCheckUp: IMedicalCheckUpModel) {
      console.debug('UsersStore - removeUserMedicalCheckUp', { medicalCheckUp })
      state.userMedicalCheckUps = state.userMedicalCheckUps.filter(d => d.id !== medicalCheckUp.id)
      for (const documentId of medicalCheckUp.documentIds) {
        state.userDocuments = state.userDocuments.filter(d => d.id !== documentId)
      }
    },
    setUsers (state, { users, page, end, total }) {
      console.log('UsersStore - setUsers', { users, page, end, total })
      state.users = users
      state.usersPage = page
      state.usersPaginationEnd = end
      state.usersTotal = total
    }
  },

  actions: {
    async updateUser ({ commit, dispatch }, params: { id: string, user: Partial<IUserModel>, isNew: boolean | undefined }): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - updateUser', { params })

      // eslint-disable-next-line eqeqeq
      try {
        const document = params.isNew === true
          ? await setDoc(usersCollectionDoc(params.id), params.user as any)
          : await updateDoc(usersCollectionDoc(params.id), params.user)
        console.debug('UsersStore - updateUser - updated', { document })

        commit('setLoading', false)

        await dispatch('fetchUser', params.id)

        return true
      } catch (error) {
        console.error('UsersStore - updateUser - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async paginateUsers (
      { state, commit },
      params: {
        forceReload?: boolean,
        query: string
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number,
        sortField?: 'surname',
        sortDirection?: 'asc' | 'desc'
      }
    ): Promise<any | undefined> {
      console.debug('UsersStore - paginateUsers', params)
      commit('setUsersSearchQuery', params.query)

      const page = params.query === state.usersSearchQuery && !params.forceReload
        ? state.usersPage + 1
        : 0

      if (params.forceReload !== true && state.usersPaginationEnd) {
        console.debug('UsersStore - paginateUsers - pagination ended')
        return
      }

      commit('setLoading', true)
      try {
        const result = await usersIndex(algoliaSearch, params.sortField, params.sortDirection)
          .search(
            params.query,
            {
              page,
              hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
              facetFilters: params != undefined && params.facetFilters ? params.facetFilters : 'isUser:true',
              cacheable: params == undefined || params.forceReload !== true
            }
          )
        console.log('UsersStore - paginateUsers - result', result)
        commit(
          'setUsers',
          {
            page,
            users: params.forceReload === true ? result.hits : [...state.users, ...result.hits],
            end: result.hits.length < result.hitsPerPage,
            total: result.nbHits
          }
        )
        commit('setLoading', false)

        return result
      } catch (error) {
        console.error('UsersStore - error searching inside the users index', { params, error })
      }
      commit('setLoading', false)

      return undefined
    },

    async instantGetUser ({ commit }, id): Promise<IUserModel | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - instantGetUser', { id })

      // eslint-disable-next-line eqeqeq
      try {
        const document = await getDoc(usersCollectionDoc(id))
        console.debug('UsersStore - fetchUser - updated', { document })

        commit('setLoading', false)

        return document.data()
      } catch (error) {
        console.error('UsersStore - fetchUser - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return undefined
    },

    async fetchUser ({ commit, dispatch }, id): Promise<boolean> {
      commit('setLoading', true)
      commit('setUser', undefined)
      console.debug('UsersStore - fetchUser', { id })

      if (id === 'new') {
        commit('setLoading', false)
        commit('setUser', {
          id: UUID(),
          createdTs: moment().valueOf(),
          devices: {},
          isEnabled: true,
          role: UserRole.USER,
          email: '',
          name: '',
          phone: '',
          isPaymentRegular: false,
          birthDate: {
            day: 1,
            month: 1,
            year: 1980
          },
          surname: '',
          taxCode: '',
          weight: 0
        } as IUserModel)
        commit('setUserPayments', [])
        commit('setUserComments', [])
        commit('setUserEnrollments', [])
        commit('setUserDocuments', [])
        commit('setUserMedicalCheckUps', [])

        return true
      }

      // eslint-disable-next-line eqeqeq
      try {
        const document = await getDoc(usersCollectionDoc(id))
        console.debug('UsersStore - fetchUser - updated', { document })

        commit('setUser', document.data())
        await Promise.all([
          dispatch('fetchUserPayments', { userId: id, skipLoadingUpdate: true }),
          dispatch('fetchMedicalCheckUps', { userId: id, skipLoadingUpdate: true }),
          dispatch('fetchDocuments', { userId: id, skipLoadingUpdate: true }),
          dispatch('fetchComments', { userId: id, skipLoadingUpdate: true }),
          dispatch('fetchEnrollments', { userId: id, skipLoadingUpdate: true })
        ])

        commit('setLoading', false)

        return true
      } catch (error) {
        console.error('UsersStore - fetchUser - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async fetchUserPayments ({ commit }, params: { userId: string; skipLoadingUpdate?: boolean }): Promise<boolean> {
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', true)
      }
      commit('setUserPayments', [])
      console.debug('UsersStore - fetchUserPayments', { params })

      // eslint-disable-next-line eqeqeq
      try {
        const documents = await getDocs(userPaymentsCollection(params.userId))
        console.debug('UsersStore - fetchUserPayments - updated', { document })
        if (params.skipLoadingUpdate !== true) {
          commit('setLoading', false)
        }
        commit(
          'setUserPayments',
          documents.docs
            .map(d => d.data())
            .sort((a: IPaymentModel, b: IPaymentModel) => b.periodEnd - a.periodEnd)
        )

        return true
      } catch (error) {
        console.error('UsersStore - fetchUserPayments - users - error', { error })
        console.error(error)
      }
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', false)
      }
      return false
    },

    async updateUserPayment ({ commit }, params: { payment: Partial<IPaymentModel>}): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - updateUserPayment', { params })

      if (params.payment.id == undefined || params.payment.userId == undefined) {
        console.debug('UsersStore - updateUserPayment - id or userId not provided', { payment: params.payment })
        commit('setLoading', false)
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        console.debug('UsersStore - updateUserPayment - save', { payment: params.payment })

        await updateDoc(userPaymentsCollectionDoc(params.payment.userId, params.payment.id), params.payment)
        console.debug('UsersStore - updateUserPayment - updated')

        commit('setLoading', false)
        commit('setUserPayment', params.payment)

        return true
      } catch (error) {
        console.error('UsersStore - updateUserPayment - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async fetchMedicalCheckUps ({ commit }, params: { userId: string; skipLoadingUpdate?: boolean }): Promise<boolean> {
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', true)
      }
      commit('setUserMedicalCheckUps', [])
      console.debug('UsersStore - fetchMedicalCheckUps', { params })

      // eslint-disable-next-line eqeqeq
      try {
        const documents = await getDocs(userMedicalCheckUpsCollection(params.userId))
        console.debug('UsersStore - fetchMedicalCheckUps - updated', { document })
        if (params.skipLoadingUpdate !== true) {
          commit('setLoading', false)
        }
        commit('setUserMedicalCheckUps', documents.docs.map(d => d.data()))

        return true
      } catch (error) {
        console.error('UsersStore - fetchMedicalCheckUps - users - error', { error })
        console.error(error)
      }
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', false)
      }
      return false
    },

    async updateUserMedicalCheckUp ({ commit, dispatch }, params: { medicalCheckUp: Partial<IEnrollmentModel>, isNew: boolean | undefined, files?: File[] }): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - updateUserMedicalCheckUp', { params })

      if (params.medicalCheckUp.id == undefined || params.medicalCheckUp.userId == undefined) {
        console.debug('UsersStore - updateUserMedicalCheckUp - id or userId not provided', { medicalCheckUp: params.medicalCheckUp })
        commit('setLoading', false)
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        // eslint-disable-next-line eqeqeq
        if (params.files != undefined) {
          for (const file of params.files) {
            const newDocumentId = UUID()
            await dispatch(
              'updateUserDocument',
              {
                document: {
                  id: newDocumentId,
                  type: DocumentType.MEDICAL_CHECK_UP,
                  userId: params.medicalCheckUp.userId
                } as Partial<IDocumentModel>,
                isNew: true,
                file
              }
            )
            params.medicalCheckUp.documentIds = params.medicalCheckUp.documentIds != undefined
              ? [...params.medicalCheckUp.documentIds, newDocumentId]
              : [newDocumentId]
          }
        }
        console.debug('UsersStore - updateUserMedicalCheckUp - save', { medicalCheckUp: params.medicalCheckUp })

        if (params.isNew === true) {
          params.medicalCheckUp.id = UUID()
          await setDoc(userMedicalCheckUpsCollectionDoc(params.medicalCheckUp.userId, params.medicalCheckUp.id), params.medicalCheckUp as any)
        } else {
          await updateDoc(userMedicalCheckUpsCollectionDoc(params.medicalCheckUp.userId, params.medicalCheckUp.id), params.medicalCheckUp)
        }
        console.debug('UsersStore - updateUserMedicalCheckUp - updated')

        commit('setLoading', false)
        commit('setUserMedicalCheckUp', params.medicalCheckUp)

        return true
      } catch (error) {
        console.error('UsersStore - updateUserMedicalCheckUp - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async deleteUserMedicalCheckUp ({ commit }, params: { medicalCheckUp: Partial<IEnrollmentModel> }): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - deleteUserMedicalCheckUp', { params })

      if (params.medicalCheckUp.id == undefined || params.medicalCheckUp.userId == undefined) {
        console.debug('UsersStore - deleteUserMedicalCheckUp - id or userId not provided', { medicalCheckUp: params.medicalCheckUp })
        commit('setLoading', false)
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        await deleteDoc(userMedicalCheckUpsCollectionDoc(params.medicalCheckUp.userId, params.medicalCheckUp.id))
        console.debug('UsersStore - deleteUserMedicalCheckUp - updated')

        commit('setLoading', false)
        commit('removeUserMedicalCheckUp', params.medicalCheckUp)

        return true
      } catch (error) {
        console.error('UsersStore - deleteUserMedicalCheckUp - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async fetchDocuments ({ commit }, params: { userId: string; skipLoadingUpdate?: boolean }): Promise<boolean> {
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', true)
      }
      commit('setUserDocuments', [])
      console.debug('UsersStore - fetchDocuments', { params })

      // eslint-disable-next-line eqeqeq
      try {
        const documents = await getDocs(userDocumentsCollection(params.userId))
        console.debug('UsersStore - fetchDocuments - updated', { document })
        if (params.skipLoadingUpdate !== true) {
          commit('setLoading', false)
        }
        commit('setUserDocuments', documents.docs.map(d => d.data()))

        return true
      } catch (error) {
        console.error('UsersStore - fetchDocuments - users - error', { error })
        console.error(error)
      }
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', false)
      }
      return false
    },

    async updateUserDocument ({ commit }, params: { document: Partial<IDocumentModel>, isNew: boolean | undefined, file?: File }): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - updateUserDocument', { params })

      if (params.document.id == undefined || params.document.userId == undefined) {
        console.debug('UsersStore - updateUserDocument - id or userId not provided', { document: params.document })
        commit('setLoading', false)
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        if (params.isNew) {
          params.document.createdTs = moment().unix()
          if (params.document.id == 'new' || params.document.id == undefined) {
            params.document.id = UUID()
          }

          // eslint-disable-next-line eqeqeq
          if (params.file == undefined) {
            commit('setLoading', false)
            return false
          }

          params.document.mimeType = params.file.type
          params.document.title = params.file.name
          params.document.documentUrl = await uploadDocument(
            userDocumentsFolderDocumentFile(params.document.userId, params.document.id),
            params.file,
            params.document.id
          )
        }
        console.debug('UsersStore - updateUserDocument - save', { document: params.document })

        if (params.isNew === true) {
          await setDoc(userDocumentsCollectionDoc(params.document.userId, params.document.id), params.document as any)
        } else {
          await updateDoc(userDocumentsCollectionDoc(params.document.userId, params.document.id), params.document)
        }
        console.debug('UsersStore - updateUserDocument - updated')

        commit('setLoading', false)
        commit('setUserDocument', params.document)

        return true
      } catch (error) {
        console.error('UsersStore - updateUserDocument - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async deleteUserDocument ({ commit }, params: { document: Partial<IDocumentModel> }): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - deleteUserDocument', { params })

      if (params.document.id == undefined || params.document.userId == undefined) {
        console.debug('UsersStore - deleteUserDocument - id or userId not provided', { document: params.document })
        commit('setLoading', false)
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        await deleteDoc(userDocumentsCollectionDoc(params.document.userId, params.document.id))
        console.debug('UsersStore - deleteUserDocument - updated')

        commit('setLoading', false)
        commit('removeUserDocument', params.document)

        return true
      } catch (error) {
        console.error('UsersStore - deleteUserDocument - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async fetchComments ({ commit }, params: { userId: string; skipLoadingUpdate?: boolean }): Promise<boolean> {
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', true)
      }
      commit('setUserComments', [])
      console.debug('UsersStore - fetchComments', { params })

      // eslint-disable-next-line eqeqeq
      try {
        const documents = await getDocs(userCommentsCollection(params.userId))
        console.debug('UsersStore - fetchComments - updated', { document })
        if (params.skipLoadingUpdate !== true) {
          commit('setLoading', false)
        }
        commit('setUserComments', documents.docs.map(d => d.data()))

        return true
      } catch (error) {
        console.error('UsersStore - fetchComments - users - error', { error })
        console.error(error)
      }
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', false)
      }
      return false
    },

    async createUserComment ({ commit, rootState }, params: { userId: string, text: string }): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - createUserComment', { params })

      if (params.userId == undefined) {
        console.debug('UsersStore - createUserComment - id or userId not provided', { comment: params.userId })
        commit('setLoading', false)
        return false
      }

      const authUser: User = (rootState as any).auth.authUser
      // eslint-disable-next-line eqeqeq
      if (authUser == undefined) {
        console.debug('UsersStore - createUserComment - auth user not provided', { authUser })
        commit('setLoading', false)
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        const comment = {
          id: UUID(),
          createdByUserId: authUser.uid,
          createdTs: moment().unix(),
          text: params.text,
          userId: params.userId
        } as ICommentModel

        await setDoc(userCommentsCollectionDoc(params.userId, comment.id), comment as any)
        console.debug('UsersStore - createUserComment - set')

        commit('setLoading', false)
        commit('setUserComment', comment)

        return true
      } catch (error) {
        console.error('UsersStore - createUserComment - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async fetchEnrollments ({ commit }, params: { userId: string; skipLoadingUpdate?: boolean }): Promise<boolean> {
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', true)
      }
      commit('setUserEnrollments', [])
      console.debug('UsersStore - fetchEnrollments', { params })

      // eslint-disable-next-line eqeqeq
      try {
        const documents = await getDocs(userEnrollmentsCollection(params.userId))
        console.debug('UsersStore - fetchEnrollments - updated', { document })
        if (params.skipLoadingUpdate !== true) {
          commit('setLoading', false)
        }
        commit(
          'setUserEnrollments',
          documents.docs.map(d => d.data())
            .sort(
              (a: IEnrollmentModel, b: IEnrollmentModel) =>
                b.dueDateTs - a.dueDateTs
            )
        )

        return true
      } catch (error) {
        console.error('UsersStore - fetchEnrollments - users - error', { error })
        console.error(error)
      }
      if (params.skipLoadingUpdate !== true) {
        commit('setLoading', false)
      }
      return false
    },

    async updateUserEnrollment (
      { commit, dispatch },
      params: {
        enrollment: Partial<IEnrollmentModel>,
        isNew: boolean | undefined,
        files?: File[]
      }
    ): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - updateUserEnrollment', { params })

      if (params.enrollment.id == undefined || params.enrollment.userId == undefined) {
        console.debug('UsersStore - updateUserEnrollment - id or userId not provided', { enrollment: params.enrollment })
        commit('setLoading', false)
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        // eslint-disable-next-line eqeqeq
        if (params.files != undefined) {
          for (const file of params.files) {
            const newDocumentId = UUID()
            await dispatch(
              'updateUserDocument',
              {
                document: {
                  id: newDocumentId,
                  type: DocumentType.ENROLLMENT,
                  userId: params.enrollment.userId
                } as Partial<IDocumentModel>,
                isNew: true,
                file
              }
            )
            params.enrollment.documentIds = params.enrollment.documentIds != undefined
              ? [...params.enrollment.documentIds, newDocumentId]
              : [newDocumentId]
          }
        }
        console.debug('UsersStore - updateUserEnrollment - save', { enrollment: params.enrollment })

        if (params.isNew === true) {
          params.enrollment.id = UUID()
          await setDoc(userEnrollmentsCollectionDoc(params.enrollment.userId, params.enrollment.id), params.enrollment as any)
        } else {
          await updateDoc(userEnrollmentsCollectionDoc(params.enrollment.userId, params.enrollment.id), params.enrollment)
        }
        console.debug('UsersStore - updateUserEnrollment - updated')

        commit('setLoading', false)
        commit('setUserEnrollment', params.enrollment)

        return true
      } catch (error) {
        console.error('UsersStore - updateUserEnrollment - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },

    async deleteUserEnrollment ({ commit }, params: { enrollment: Partial<IEnrollmentModel> }): Promise<boolean | undefined> {
      commit('setLoading', true)
      console.debug('UsersStore - deleteUserEnrollment', { params })

      if (params.enrollment.id == undefined || params.enrollment.userId == undefined) {
        console.debug('UsersStore - deleteUserEnrollment - id or userId not provided', { enrollment: params.enrollment })
        commit('setLoading', false)
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        await deleteDoc(userEnrollmentsCollectionDoc(params.enrollment.userId, params.enrollment.id))
        console.debug('UsersStore - deleteUserEnrollment - updated')

        commit('setLoading', false)
        commit('removeUserEnrollment', params.enrollment)

        return true
      } catch (error) {
        console.error('UsersStore - deleteUserEnrollment - users - error', { error })
        console.error(error)
      }

      commit('setLoading', false)
      return false
    },
    async deleteUser (_, id: string): Promise<boolean> {
      console.debug('UsersStore - deleteUser - result', { id })

      // Function User Callable
      try {
        const result = await deleteUserCallable({ id })
        console.debug('UsersStore - deleteUser - result', { result })
      } catch (error) {
        console.debug('UsersStore - deleteUser - error', { error })
        console.debug(error)

        return false
      }

      return true
    }
  }
}
