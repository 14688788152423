
import { ICommentModel, IUserModel } from '@boxe-riviera-friulana/shared'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'

const usersHelper = createNamespacedHelpers('users')
const authHelper = createNamespacedHelpers('auth')

export default defineComponent({
  name: 'CommentsBubble',

  props: {
    modelValue: {
      type: Object as () => ICommentModel[], required: true
    }
  },

  data () {
    return {
      messagesUsers: [] as IUserModel[],
      moment
    }
  },

  computed: {
    ...authHelper.mapState(['user']),
    commentsOrdered: function () {
      return this.modelValue.slice().sort((a, b) => a.createdTs - b.createdTs)
    }
  },

  methods: {
    ...usersHelper.mapActions(['instantGetUser']),

    createdByUser (comment: ICommentModel): IUserModel | undefined {
      return this.messagesUsers.find(u => u.id === comment.createdByUserId)
    }
  },

  async mounted () {
    // eslint-disable-next-line eqeqeq
    if (this.user == undefined) { return }
    this.messagesUsers.push(this.user)
    for (const comment of this.modelValue) {
      if (this.createdByUser(comment) === undefined) {
        this.messagesUsers.push(
          await this.instantGetUser(comment.createdByUserId)
        )
      }
    }
  }
})
