import { initializeApp } from 'firebase/app'
import { Functions, getFunctions, HttpsCallable, httpsCallableFromURL } from 'firebase/functions'
import {
  getFirestore, collection, CollectionReference, doc,
  DocumentReference
} from 'firebase/firestore'
import { getStorage, uploadBytes, ref as getStorageRef, StorageReference, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import config from '@/../google-services.json'
import { readAndCompressImage } from 'browser-image-resizer'
import { IDocumentModel, IEnrollmentModel, IPaymentModel, IUserModel } from '@boxe-riviera-friulana/shared'

// Get the firebase instance
export const app = initializeApp(config)

// Get a Firestore instance
export const db = getFirestore(app)

// Get a Auth instance
export const auth = getAuth(app)

// Get a Storage instance
export const storage = getStorage(app)

// Get a Functions instance
export const functions: Functions = getFunctions(app, 'europe-west3')

// Define the collections
export const usersCollectionName = process.env.NODE_ENV === 'production' ? 'users' : 'usersDevelopment'
export const usersCollection = collection(db, usersCollectionName) as CollectionReference<IUserModel>
export const usersCollectionDoc = (docId: string): DocumentReference<IUserModel> => doc(db, usersCollection.path, docId) as DocumentReference<IUserModel>

export const userPaymentsCollectionName = process.env.NODE_ENV === 'production' ? 'payments' : 'paymentsDevelopment'
export const userPaymentsCollection = (userId: string): CollectionReference<IPaymentModel> => collection(
  db, `${usersCollectionDoc(userId).path}/${userPaymentsCollectionName}`
) as CollectionReference<IPaymentModel>
export const userPaymentsCollectionDoc = (userId: string, docId: string): DocumentReference<IPaymentModel> => doc(
  db, userPaymentsCollection(userId).path, docId
) as DocumentReference<IPaymentModel>

export const userMedicalCheckUpsCollectionName = process.env.NODE_ENV === 'production' ? 'medicalCheckUps' : 'medicalCheckUpsDevelopment'
export const userMedicalCheckUpsCollection = (userId: string): CollectionReference<IPaymentModel> => collection(
  db, `${usersCollectionDoc(userId).path}/${userMedicalCheckUpsCollectionName}`
) as CollectionReference<IPaymentModel>
export const userMedicalCheckUpsCollectionDoc = (userId: string, docId: string): DocumentReference<IPaymentModel> => doc(
  db, userMedicalCheckUpsCollection(userId).path, docId
) as DocumentReference<IPaymentModel>

export const userEnrollmentsCollectionName = process.env.NODE_ENV === 'production' ? 'enrollments' : 'enrollmentsDevelopment'
export const userEnrollmentsCollection = (userId: string): CollectionReference<IEnrollmentModel> => collection(
  db, `${usersCollectionDoc(userId).path}/${userEnrollmentsCollectionName}`
) as CollectionReference<IEnrollmentModel>
export const userEnrollmentsCollectionDoc = (userId: string, docId: string): DocumentReference<IEnrollmentModel> => doc(
  db, userEnrollmentsCollection(userId).path, docId
) as DocumentReference<IEnrollmentModel>

export const userDocumentsCollectionName = process.env.NODE_ENV === 'production' ? 'documents' : 'documentsDevelopment'
export const userDocumentsCollection = (userId: string): CollectionReference<IDocumentModel> => collection(
  db, `${usersCollectionDoc(userId).path}/${userDocumentsCollectionName}`
) as CollectionReference<IDocumentModel>
export const userDocumentsCollectionDoc = (userId: string, docId: string): DocumentReference<IDocumentModel> => doc(
  db, userDocumentsCollection(userId).path, docId
) as DocumentReference<IDocumentModel>

export const userCommentsCollectionName = process.env.NODE_ENV === 'production' ? 'comments' : 'commentsDevelopment'
export const userCommentsCollection = (userId: string): CollectionReference<IDocumentModel> => collection(
  db, `${usersCollectionDoc(userId).path}/${userCommentsCollectionName}`
) as CollectionReference<IDocumentModel>
export const userCommentsCollectionDoc = (userId: string, docId: string): DocumentReference<IDocumentModel> => doc(
  db, userCommentsCollection(userId).path, docId
) as DocumentReference<IDocumentModel>

// Define the functions
const baseUrl = 'https://europe-west3-boxe-riviera.cloudfunctions.net/'
export const getRemoteConfigsCallableFunctionName = process.env.NODE_ENV === 'production' ? `${baseUrl}getRemoteConfigsCallable` : `${baseUrl}development-getRemoteConfigsCallable`
export const getRemoteConfigsCallable: HttpsCallable<undefined, { code: number, data: any }> = httpsCallableFromURL(functions, getRemoteConfigsCallableFunctionName)
export const updatedeleteUserCallableName = process.env.NODE_ENV === 'production' ? `${baseUrl}deleteUserCallable` : `${baseUrl}development-deleteUserCallable`
export const deleteUserCallable: HttpsCallable = httpsCallableFromURL(functions, updatedeleteUserCallableName)

// Define storage settings
export const userDocumentsFolder = (userId: string): StorageReference => getStorageRef(
  storage,
  `${process.env.NODE_ENV === 'production' ? 'users' : 'usersDevelopment'}/${userId}/${userDocumentsCollectionName}`
)
export const userDocumentsFolderDocumentFile = (userId: string, documentId: string): StorageReference => getStorageRef(
  storage,
  `${userDocumentsFolder(userId).fullPath}/${documentId}`
)

export const uploadDocument = async (destination: StorageReference, file: File, name: string): Promise<string | undefined> => {
  let target: Blob = file

  if (/^image\/.*/.test(file.type)) {
    target = await readAndCompressImage(file, {
      quality: 0.7,
      maxWidth: 1000,
      maxHeight: 1000,
      mimeType: 'image/jpeg'
    })
  }
  console.debug('uploadDocument', { data: target })

  try {
    const uploadTask = await uploadBytes(
      getStorageRef(storage, `${destination.fullPath}/${name}`),
      target
    )

    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    const downloadUrl = await getDownloadURL(uploadTask.ref)
    console.log('File available at', downloadUrl)

    return downloadUrl
  } catch (error) {
    console.log('Upload failed', error)
  }
}
