import { createStore } from 'vuex'
import Cookies from 'js-cookie'
import VuexPersistence from 'vuex-persist'

import { AuthenticationStore } from './authentication.store'
import { NavigationStore } from './navigation.store'
import { UsersStore } from './users.store'

const vuexCookie = new VuexPersistence({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  restoreState: (key: any) => {
    const value = Cookies.get(key)
    if (value) {
      return JSON.parse(value)
    }
    return undefined
  },
  saveState: (key: any, state: any) => {
    return Cookies.set(key, JSON.stringify(state), {
      expires: 2
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any
  },
  modules: ['auth'], // only save user module
  filter: (mutation: any) =>
    mutation.type === 'auth/login' || mutation.type === 'auth/logout'
})

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['navigation', 'catalogs'], // only save user module
  filter: (mutation: any) =>
    mutation.type === 'navigation/navigation' ||
    mutation.type === 'configs/setCatalogs' ||
    mutation.type === 'configs/setCatalogsPendingChanges'
})

export default createStore({
  modules: {
    auth: AuthenticationStore,
    navigation: NavigationStore,
    users: UsersStore
  },

  plugins: [vuexCookie.plugin, vuexLocal.plugin]
})
