import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import moment from 'moment'
import { VueFire, VueFireAuth, VueFireFirestoreOptionsAPI } from 'vuefire'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { app as firebaseApp } from './utilities'

import './assets/tailwind.css'

/* Theme variables */
import './assets/variables.css'

// Font Awesome
library.add(fas, far, fab)

// Set Moment language
moment.locale('it')
// moment.tz.setDefault('Europe/Rome')

createApp(App)
  .use(store)
  .use(router)

  .use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
      // we will see other modules later on
      VueFireAuth(),
      VueFireFirestoreOptionsAPI()
    ]
  })

  // Font Awesome
  .component('font-awesome-icon', FontAwesomeIcon)

  .mount('#app')
