
/* eslint-disable operator-linebreak */
import { AthleteType, EnrollmentType, IDocumentModel, IEnrollmentModel } from '@boxe-riviera-friulana/shared'
import moment from 'moment'
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import { arrayEquals, EnumUtility } from '../../utilities'
import { createNamespacedHelpers } from 'vuex'
import { IUsersStoreState } from '@/interfaces'

const usersHelper = createNamespacedHelpers('users')

// TODO: Show the loader (based form the users Vuex Store)

export default defineComponent({
  name: 'UserEnrollmentDialog',

  emits: ['close', 'save'],

  components: { ModalDialog },

  props: {
    modelValue: { type: Object as () => IEnrollmentModel, required: true }
  },

  data: function () {
    return {
      newFile: undefined,
      files: [] as File[],

      editingEnrollment: {
        cardNumber: '',
        athleteType: undefined,
        enrollmentType: undefined,
        issueDateTs: undefined,
        documentIds: [],
        dueDateTs: undefined
      } as Partial<IEnrollmentModel>,

      moment,
      // eslint-disable-next-line object-property-newline
      EnrollmentType, AthleteType,
      EnumUtility
    }
  },

  computed: {
    ...usersHelper.mapState<IUsersStoreState>({
      documents (state: IUsersStoreState) {
        // eslint-disable-next-line eqeqeq
        if ((this as any).modelValue == undefined) { return [] }
        return state.userDocuments.filter(d => (this as any).editingEnrollment.documentIds.includes(d.id))
      }
    }),
    isNew: function () { return this.modelValue.id === 'new' }
  },

  methods: {
    ...usersHelper.mapActions(['updateUserEnrollment']),

    loadedFileName: function (file: File): string {
      // eslint-disable-next-line eqeqeq
      return file == undefined || file == undefined ? '' : file.name
    },
    onDeleteFile (file: File) {
      this.files = this.files.filter(f => f.name !== file.name)
    },
    onDeleteAttachedDocument (document: IDocumentModel) {
      // eslint-disable-next-line eqeqeq
      if (this.editingEnrollment.documentIds == undefined) { return }
      this.editingEnrollment.documentIds = this.editingEnrollment.documentIds.filter(id => id !== document.id)
    },
    onIssueDateChange (change: any) {
      console.debug('UserEnrollmentDialog - onIssueDateChange', { change })
      this.editingEnrollment.issueDateTs = moment(change.target.value, 'YYYY-MM-DD').unix()
    },
    onDueDateChange (change: any) {
      console.debug('UserEnrollmentDialog - onDueDateChange', { change })
      this.editingEnrollment.dueDateTs = moment(change.target.value, 'YYYY-MM-DD').unix()
    },

    onSelectFile: function () {
      (this.$refs.fileInput as any).click()
    },
    async onSave () {
      console.debug('UserEnrollmentDialog - onSave')

      if (
        this.isNew
        || this.editingEnrollment.cardNumber !== this.modelValue.cardNumber
        || this.editingEnrollment.athleteType !== this.modelValue.athleteType
        || this.editingEnrollment.enrollmentType !== this.modelValue.enrollmentType
        || this.editingEnrollment.dueDateTs !== this.modelValue.dueDateTs
        || this.editingEnrollment.issueDateTs !== this.modelValue.issueDateTs
        || this.files.length > 0
        // eslint-disable-next-line eqeqeq
        || (this.editingEnrollment.documentIds != undefined && !arrayEquals(this.editingEnrollment.documentIds, this.modelValue.documentIds))
      ) {
        console.debug('UserEnrollmentDialog - onSave - perform update')
        await this.updateUserEnrollment({
          enrollment: this.editingEnrollment,
          files: this.files,
          isNew: this.isNew
        })
      } else {
        console.debug('UserEnrollmentDialog - onSave - skip due no changes')
      }

      this.$emit('save')
      this.$emit('close')
    },

    onFileInputChange: function (event: any) {
      console.log('onFileInputChange', { image: (event as any).target.files })
      // Reference to the DOM input element
      const input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files) {
        for (let i = 0; i < input.files.length; i++) {
          this.files.push(input.files[i])
        }
      }
    }

  },

  mounted () {
    this.editingEnrollment = { ...this.modelValue }
  }
})
