
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import LoaderScreen from '@/components/LoaderScreen.vue'
import { createNamespacedHelpers } from 'vuex'
import { IUserModel } from '@boxe-riviera-friulana/shared'

const usersStoreHelper = createNamespacedHelpers('users')

export default defineComponent({
  name: 'DeleteUserModal',

  emits: ['close', 'confirm'],

  components: { ModalDialog, LoaderScreen },

  props: {
    modelValue: { type: Object as () => IUserModel, required: true }
  },

  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    ...usersStoreHelper.mapActions(['deleteUser']),
    async onDelete () {
      console.debug('AdminUserDisableModal - onDelete')
      // eslint-disable-next-line eqeqeq
      if (this.modelValue == undefined || this.modelValue.id == undefined) { return }
      this.isLoading = true

      const result = await this.deleteUser(this.modelValue.id)

      if (!result) {
        console.log('Error Deleting the user')
        // TODO: Show error

        return
      }
      this.$emit('close')
      this.$emit('confirm')
      this.isLoading = false
    }
  }
})
