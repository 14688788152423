/* eslint-disable import/no-duplicates */
import { SearchClient } from 'algoliasearch'
import config from '@/../algolia.json'
import algoliasearch from 'algoliasearch'

// Define the indexes
export const usersIndex = (
  client: SearchClient,
  sortField?: 'surname' | 'lastEnrollment.dueDateTs' | 'lastMedicalCheckUp.dueDateTs',
  sortDirection?: 'asc' | 'desc'
) => {
  const indexPrefix = process.env.NODE_ENV === 'production' ? 'prod_users' : 'dev_users'

  switch (sortField) {
    case 'lastMedicalCheckUp.dueDateTs':
      return client.initIndex(`${indexPrefix}${sortDirection === undefined || sortDirection === 'desc' ? '_medical_check_up_due_date_desc' : '_medical_check_up_due_date_asc'}`)
    case 'lastEnrollment.dueDateTs':
      return client.initIndex(`${indexPrefix}${sortDirection === undefined || sortDirection === 'desc' ? '_enrollment_due_date_desc' : '_enrollment_due_date_asc'}`)
    case 'surname':
    default:
      return client.initIndex(`${indexPrefix}${sortDirection === undefined || sortDirection === 'desc' ? '' : '_asc'}`)
  }
}
// Get the algolia search instance
export const algoliaSearch = algoliasearch(config.projectId, config.clientKey)
