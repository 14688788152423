
/* eslint-disable operator-linebreak */
import { IUsersStoreState } from '@/interfaces'
import { IMedicalCheckUpModel, IDocumentModel } from '@boxe-riviera-friulana/shared'
import moment from 'moment'
import { defineComponent } from 'vue'
import { arrayEquals } from '../../utilities'
import { createNamespacedHelpers } from 'vuex'
import ModalDialog from './ModalDialog.vue'

const usersHelper = createNamespacedHelpers('users')

export default defineComponent({
  name: 'UserMedicalCheckUp',

  emits: ['close', 'save'],

  components: { ModalDialog },

  props: {
    modelValue: { type: Object as () => IMedicalCheckUpModel, required: true }
  },

  data: function () {
    return {
      newFile: undefined,
      files: [] as File[],

      editingMedicalCheckUp: {
        description: '',
        issueDateTs: undefined,
        documentIds: [],
        dueDateTs: undefined
      } as Partial<IMedicalCheckUpModel>,

      moment
    }
  },

  computed: {
    ...usersHelper.mapState<IUsersStoreState>({
      documents (state: IUsersStoreState) {
        // eslint-disable-next-line eqeqeq
        if ((this as any).modelValue == undefined) { return [] }
        return state.userDocuments.filter(d => (this as any).editingMedicalCheckUp.documentIds.includes(d.id))
      }
    }),
    isNew: function () { return this.modelValue.id === 'new' }
  },

  methods: {
    ...usersHelper.mapActions(['updateUserMedicalCheckUp']),

    loadedFileName: function (file: { file: File, url: string }): string {
      // eslint-disable-next-line eqeqeq
      return file == undefined || file.file == undefined ? '' : file.file.name
    },
    onDeleteFile (file: File) {
      console.debug('UserMedicalCheckUp - onDeleteFile', { file })
      this.files = this.files.filter(f => f.name !== file.name)
    },
    onDeleteAttachedDocument (document: IDocumentModel) {
      console.debug('UserMedicalCheckUp - onDeleteAttachedDocument', { document })
      // eslint-disable-next-line eqeqeq
      if (this.editingMedicalCheckUp.documentIds == undefined) { return }
      this.editingMedicalCheckUp.documentIds = this.editingMedicalCheckUp.documentIds.filter(id => id !== document.id)
    },
    onIssueDateChange (change: any) {
      console.debug('UserEnrollmentDialog - onIssueDateChange', { change })
      this.editingMedicalCheckUp.issueDateTs = moment(change.target.value, 'YYYY-MM-DD').unix()
    },
    onDueDateChange (change: any) {
      console.debug('UserEnrollmentDialog - onDueDateChange', { change })
      this.editingMedicalCheckUp.dueDateTs = moment(change.target.value, 'YYYY-MM-DD').unix()
    },

    onSelectFile: function () {
      (this.$refs.fileInput as any).click()
    },
    async onSave () {
      console.log('onSave')
      this.$emit('close')

      if (
        this.isNew
        || this.editingMedicalCheckUp.description !== this.modelValue.description
        || this.editingMedicalCheckUp.dueDateTs !== this.modelValue.dueDateTs
        || this.editingMedicalCheckUp.issueDateTs !== this.modelValue.issueDateTs
        || this.files.length > 0
        // eslint-disable-next-line eqeqeq
        || (this.editingMedicalCheckUp.documentIds != undefined && !arrayEquals(this.editingMedicalCheckUp.documentIds, this.modelValue.documentIds))
      ) {
        await this.updateUserMedicalCheckUp({
          medicalCheckUp: this.editingMedicalCheckUp,
          files: this.files,
          isNew: this.isNew
        })
      }

      this.$emit('save')
      this.$emit('close')
    },
    onFileInputChange: function (event: any) {
      console.log('onFileInputChange', { image: (event as any).target.files })
      // Reference to the DOM input element
      const input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files) {
        for (let i = 0; i < input.files.length; i++) {
          this.files.push(input.files[i])
        }
      }
    }
  },

  mounted () {
    this.editingMedicalCheckUp = { ...this.modelValue }
  }
})
