import { AthleteType, EnrollmentType } from '@boxe-riviera-friulana/shared'

export class EnumUtility {
  static athleteTypeTranslated (value: AthleteType) {
    switch (value) {
      case AthleteType.COMPONENT: return 'Componente'
      case AthleteType.ELITE: return 'Elite'
      case AthleteType.GIM_BOX: return 'Gym Boxe'
      case AthleteType.JOEY: return 'Cangurino'
      case AthleteType.PREPUGILISTICS: return 'Prepugilistica'
      case AthleteType.PRESIDENT: return 'Presidente'
      case AthleteType.YOUTH: return 'Youth'
    }
  }

  static enrollmentTypeTranslated (value: EnrollmentType) {
    switch (value) {
      case EnrollmentType.AMATEUR: return 'Amatoriale'
      case EnrollmentType.NON_PROFESSIONAL: return 'Dilettante'
      case EnrollmentType.MANAGER: return 'Manager'
      case EnrollmentType.JUNIOR: return 'Junior'
    }
  }
}
