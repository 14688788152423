
/* eslint-disable object-property-newline */
import { defineComponent } from 'vue'
import UserDocumentDialog from '@/components/modal-dialogs/UserDocumentDialog.vue'
import UserEnrollmentDialog from '@/components/modal-dialogs/UserEnrollmentDialog.vue'
import UserMedicalCheckUp from '@/components/modal-dialogs/UserMedicalCheckUp.vue'
import ConfirmEditDialog from '@/components/modal-dialogs/ConfirmEditDialog.vue'
import DeleteDocumentDialog from '@/components/modal-dialogs/DeleteDocumentDialog.vue'
import DeleteMedicalCheckUpDialog from '@/components/modal-dialogs/DeleteMedicalCheckUpDialog.vue'
import DeleteEnrollmentDialog from '@/components/modal-dialogs/DeleteEnrollmentDialog.vue'
import SavingReminderDialog from '@/components/modal-dialogs/SavingReminderDialog.vue'
import { AthleteType } from '@/enums/athlete-type.enum'
import { EnrollmentType } from '@/enums/enrollment-type.enum'
import { DocumentType } from '@/enums/document-type.enum'
import { PaymentStatus } from '@/enums/payment-status.enum'
import SortedTable from '@/components/sorted-table/SortedTable.vue'
import SortLink from '@/components/sorted-table/SortLink.vue'
import LoaderScreen from '@/components/LoaderScreen.vue'
import CheckBox from '@/components/CheckBox.vue'
import CommentsBubble from '@/components/CommentsBubble.vue'
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
import { IDocumentModel, IEnrollmentModel, IMedicalCheckUpModel, IPaymentModel, IUserModel } from '@boxe-riviera-friulana/shared'

const usersHelper = createNamespacedHelpers('users')

export default defineComponent({
  name: 'MemberDetailPage',

  components: {
    SortedTable, SortLink, UserDocumentDialog, UserEnrollmentDialog, UserMedicalCheckUp, SavingReminderDialog,
    ConfirmEditDialog, DeleteDocumentDialog, DeleteMedicalCheckUpDialog, CheckBox, CommentsBubble, LoaderScreen,
    DeleteEnrollmentDialog
  },

  data () {
    return {
      moment,
      isReloading: false,
      isEditing: false,
      isShowingDocumentModalDialog: false,
      isShowingEnrollmentModalDialog: false,
      isShowingMedicalCheckUpModalDialog: false,
      isShowingDocumentDeleteDialog: false,
      isShowingMedicalCheckUpDeleteDialog: false,
      isShowingEnrollmentDeleteDialog: false,
      isShowingCompleteUserModalDialog: false,
      isNew: false,
      deletingDocumentName: '',
      sortDirection: 'desc',
      sortField: 'createdTs',

      deletingEnrollment: undefined as IEnrollmentModel | undefined,
      deletingMedicalCheckUp: undefined as IMedicalCheckUpModel | undefined,
      deletingDocument: undefined as IDocumentModel | undefined,

      editingComment: '',
      editingEnrollment: {} as Partial<IEnrollmentModel>,
      editingDocument: {} as Partial<IDocumentModel>,
      editingMedicalCheckUpModal: {} as Partial<IMedicalCheckUpModel>,
      editingUser: {
        name: '',
        surname: '',
        birthDate: {
          year: 1980,
          month: 1,
          day: 1
        },
        taxCode: ''
      } as Partial<IUserModel>,

      AthleteType, EnrollmentType, DocumentType,
      PaymentStatus
    }
  },

  computed: {
    ...usersHelper.mapGetters(['userGenericDocuments']),
    ...usersHelper.mapState(['user', 'userComments', 'userDocuments', 'userEnrollments', 'userMedicalCheckUps', 'userPayments', 'isLoading']),
    birthDate () {
      // eslint-disable-next-line eqeqeq
      return (this as any).user != undefined ? (this as any).birthDateToString((this as any).user.birthDate) : undefined
    },
    editingBirthDate () {
      // eslint-disable-next-line eqeqeq
      return (this as any).editingUser != undefined ? (this as any).birthDateToString((this as any).editingUser.birthDate) : undefined
    }
  },

  methods: {
    ...usersHelper.mapActions(['fetchUser', 'updateUser', 'createUserComment', 'updateUserPayment']),

    paymentPeriodString (payment: IPaymentModel) {
      const start = moment.unix(payment.periodStart)
      const end = moment.unix(payment.periodEnd)
      const now = moment()

      if (start.isSame(end, 'month') && start.isSame(now, 'year') && end.isSame(now, 'year')) {
        const str = start.format('MMMM')
        return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`
      }

      return `${start.format('DD/MM/YY')} - ${end.format('DD/MM/YY')}`
    },

    editUserOnClick () {
      console.log('MemberDetailPage - editUserOnClick')
      if (this.isEditing) {
        this.onUserUpdate()
      } else {
        this.editingUser = this.user
        this.isEditing = true
      }
    },
    addMembershipOnClick () {
      this.isShowingEnrollmentModalDialog = !this.isShowingEnrollmentModalDialog
    },
    preventGoingBack () {
      this.isReloading = true
      console.log('isReloading:', this.isReloading)
      setTimeout(() => {
        this.$router.push('/members')
      }, 2000)
    },

    onEnrollmentDelete (item: IEnrollmentModel) {
      console.log('MemberDetailPage - onEnrollmentDelete', { item })
      this.deletingEnrollment = item
      this.isShowingEnrollmentDeleteDialog = true
    },
    onMedicalCheckUpDelete (item: IMedicalCheckUpModel) {
      console.log('MemberDetailPage - onMedicalCheckUpDelete', { item })
      this.deletingMedicalCheckUp = item
      this.isShowingMedicalCheckUpDeleteDialog = true
    },
    onDocumentDelete (item: IDocumentModel) {
      console.log('MemberDetailPage - onDocumentDelete', { item })
      this.deletingDocument = item
      this.isShowingDocumentDeleteDialog = true
    },
    onRelatedAttachmentDownload (item: IEnrollmentModel | IMedicalCheckUpModel) {
      console.log('MemberDetailPage - onRelatedAttachmentDownload', { item })

      for (const id of item.documentIds) {
        console.debug(`MemberDetailPage - onRelatedAttachmentDownload - ${id}`)
        const document: IDocumentModel | undefined = this.userDocuments.find((d: IDocumentModel) => d.id === id)

        console.debug(`MemberDetailPage - onRelatedAttachmentDownload - ${id} - document`, { document })
        if (document) {
          (window as any).open(document.documentUrl, id)
        }
      }
    },
    async onPaymentStatusChange (evt: boolean, payment: IPaymentModel) {
      console.log('MemberDetailPage - onPaymentStatusChange', { event: evt, payment })
      await this.updateUserPayment({
        payment: {
          ...payment,
          updatedTs: moment().unix(),
          status: evt ? PaymentStatus.PAID : (moment.unix(payment.periodEnd).isBefore(moment(), 'month') ? PaymentStatus.NOT_PAID : PaymentStatus.PENDING)
        } as Partial<IPaymentModel>
      })
    },
    async onSendComment () {
      console.log('MemberDetailPage - onSendComment', { comment: this.editingComment })
      const comment = this.editingComment.trim()
      if (comment.length < 1) { return }

      const result = await this.createUserComment({
        userId: this.editingUser.id,
        text: comment
      })

      if (result) {
        this.editingComment = ''
      }
    },
    async onUserUpdate () {
      this.isLoading = true
      console.log('MemberDetailPage - onUserUpdate')
      const result = await this.updateUser({
        id: this.editingUser.id,
        user: this.editingUser,
        isNew: this.isNew
      })

      if (result) {
        this.isEditing = false

        if (this.isNew) {
          this.$router.replace(`/members/${this.editingUser.id}`)
        }
      }
      console.log('user fetched')
      this.isLoading = false
    },
    async onUserStatusChange (change: any) {
      console.log('MemberDetailPage - onUserStatusChange', { change: change.target.value })
      if (this.isNew) {
        this.editingUser.isEnabled = change.target.value === 'true'
        return
      }

      const result = await this.updateUser({
        id: this.editingUser.id,
        user: { isEnabled: change.target.value === 'true' },
        isNew: false
      })
    },
    onDocumentEdit (document: IDocumentModel | undefined) {
      if (this.isNew) {
        this.isShowingCompleteUserModalDialog = true
        return
      }

      this.editingDocument = document === undefined
        ? {
            id: 'new',
            createdTs: moment().unix(),
            description: '',
            userId: this.editingUser.id,
            type: DocumentType.GENERIC
          }
        : document
      this.isShowingDocumentModalDialog = true
    },
    onEnrollmentEdit (enrollment: IEnrollmentModel | undefined) {
      if (this.isNew) {
        this.isShowingCompleteUserModalDialog = true
        return
      }

      this.editingEnrollment = enrollment === undefined
        ? {
            id: 'new',
            cardNumber: '',
            documentIds: [],
            athleteType: undefined,
            enrollmentType: undefined,
            userId: this.editingUser.id,
            issueDateTs: undefined,
            dueDateTs: undefined
          }
        : enrollment
      this.isShowingEnrollmentModalDialog = true
    },
    onMedicalCheckUpEdit (medicalCheckUpEdit: IMedicalCheckUpModel | undefined) {
      if (this.isNew) {
        this.isShowingCompleteUserModalDialog = true
        return
      }

      this.editingMedicalCheckUpModal = medicalCheckUpEdit === undefined
        ? {
            id: 'new',
            description: '',
            documentIds: [],
            userId: this.editingUser.id,
            issueDateTs: undefined,
            dueDateTs: undefined
          }
        : medicalCheckUpEdit
      this.isShowingMedicalCheckUpModalDialog = true
    },
    onBirthDateChange (evt: any) {
      const value = evt.target.value
      console.debug('onBirthDateChange', { value })
      // eslint-disable-next-line eqeqeq
      if (value != undefined && typeof value === 'string' && value.length === 10) {
        const date = moment(value, 'YYYY-MM-DD')
        if (date.isValid()) {
          this.editingUser.birthDate = {
            year: date.get('year'),
            month: date.get('month'),
            day: date.date()
          }
        }
      }
    },

    birthDateToString (birthDate: { year: number; month: number; day: number } | undefined): string | undefined {
      // eslint-disable-next-line eqeqeq
      return birthDate != undefined
        ? moment({
          year: (this as any).user.birthDate.year,
          month: (this as any).user.birthDate.month,
          day: (this as any).user.birthDate.day
        })
          .format('YYYY-MM-DD')
        : undefined
    },
    async onTableSort (fieldName: string, direction: string) {
      console.debug('Users - onTableSort', { fieldName, direction })
      this.sortField = fieldName
      this.sortDirection = direction
      await this.fetchUser(this.$route.params.id)
    }
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        this.isNew = id === 'new'
        // eslint-disable-next-line eqeqeq
        if (this.user == undefined || id !== this.user.id) {
          await this.fetchUser(id)
          this.editingUser = this.user
        }
      },
      deep: true,
      immediate: true
    }
  }
})
