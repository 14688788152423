
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'

export default defineComponent({
  name: 'ConfirmEditDialog',

  emits: ['close', 'action', 'file'],

  components: { ModalDialog },

  data: function () {
    return {
      newFile: undefined,
      file: undefined,
      description: ''
    }
  },

  computed: {
    loadedFileMimeTypeSlag: function () {
      // eslint-disable-next-line eqeqeq
      return this.file == undefined ? '' : (this.file as any).type
    },
    loadedFileName: function () {
      // eslint-disable-next-line eqeqeq
      return this.file == undefined ? '' : (this.file as any).name
    }
  },

  methods: {

    dropFile: function (event: any) {
      console.log('dropFile')
      event.preventDefault()

      // Ensure that you have a file before attempting to read it
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        const f = event.dataTransfer.files[0]

        // eslint-disable-next-line eqeqeq
        if (f.type != undefined) {
          this.getFileImage(event.dataTransfer.files[0])
        }
      }
    },
    onSelectFile: function () {
      (this.$refs.fileInput as any).click()
    },
    onSave: function () {
      console.log('onSave')
      if (this.file !== undefined) {
        this.$emit('file', { file: this.file, url: this.newFile })
      }
      this.$emit('close')
    },
    getFileImage: function (file: any) {
      console.log('getFileImage', { file })
      // create a new FileReader to read this image and convert to base64 format
      const reader = new FileReader()
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e: any) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData
        this.file = file
        this.newFile = e.target.result
      }
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(file)
    },
    onFileInputChange: function (event: any) {
      console.log('onFileInputChange', { image: (event as any).target.files[0] })
      // Reference to the DOM input element
      const input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        this.getFileImage(input.files[0])
      }
    }
  }
})
