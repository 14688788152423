
import MainNavigationBar from '@/components/MainNavigationBar.vue'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { IUserModel } from '@boxe-riviera-friulana/shared'
import { IAuthenticationStoreState, INavigationStoreState } from './interfaces'
const authHelper = createNamespacedHelpers('auth')
const navHelper = createNamespacedHelpers('navigation')

export default defineComponent({
  name: 'App',

  components: { MainNavigationBar },

  computed: {
    ...authHelper.mapState<IAuthenticationStoreState>(['user']),
    ...navHelper.mapState<INavigationStoreState>(['lastVisitedUrl'])
  },

  methods: {
    ...authHelper.mapActions(['bindAuthUser']),
    continueWithNavigation: function () {
      console.debug('continueWithNavigation', { lastVisitedUrl: (this as any).lastVisitedUrl })
      this.$router.replace(
        // eslint-disable-next-line eqeqeq
        (this as any).lastVisitedUrl != undefined &&
          typeof (this as any).lastVisitedUrl === 'string'
          ? (this as any).lastVisitedUrl
          : '/members'
      )
    }
  },

  watch: {
    user: {
      handler: async function (user: IUserModel | undefined | null) {
        console.log('App - user', { user })
        // eslint-disable-next-line eqeqeq
        if (user == undefined || typeof user !== 'object' || !user.isEnabled) {
          console.log('App - user - not valid')
          return this.$router.replace('/auth/login')
        }

        return (this as any).continueWithNavigation()
      }
    }
  },

  mounted () {
    this.bindAuthUser()
  }
})
