
import { defineComponent } from 'vue'
import SortedTable from '@/components/sorted-table/SortedTable.vue'
import SortLink from '@/components/sorted-table/SortLink.vue'
import LoaderScreen from '@/components/LoaderScreen.vue'
import DeleteUserModal from '@/components/modal-dialogs/DeleteUserModal.vue'
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
import { IAlgoliaUserModel, IUserModel } from '@boxe-riviera-friulana/shared'
import InfiniteLoading from 'vue-infinite-loading'

const usersHelper = createNamespacedHelpers('users')

export default defineComponent({
  name: 'MembersPage',

  components: {
    SortedTable, SortLink, LoaderScreen, InfiniteLoading, DeleteUserModal
  },

  data () {
    return {
      sortDirection: 'asc',
      sortField: 'surname',
      searchQuery: '',
      totalUsers: 0,
      dateFilterStartDate: undefined,
      dateFilterEndDate: undefined,
      seeDeleteUserModal: false,
      selectedUser: undefined as IUserModel | undefined,
      expiring: false,
      isSearchbarActive: false,

      moment
    }
  },

  computed: {
    ...usersHelper.mapState(['users', 'usersPaginationEnd', 'isLoading'])
  },

  methods: {
    ...usersHelper.mapActions(['paginateUsers']),

    async userHasBeenDeleted (item: IUserModel) {
      console.debug('AdminUsers - onDisableUser', { item })
      this.selectedUser = item
      this.seeDeleteUserModal = true
    },

    currentDateIsPassedExpiration (expirationTs: number) {
      return moment().isAfter(moment.unix(expirationTs), 'days')
    },

    currentDateIsTwoMonthsFromExpiration (expirationTs: number) {
      return moment().isBefore(moment.unix(expirationTs), 'days') && moment().isAfter(moment.unix(expirationTs).subtract(2, 'M'), 'days')
    },

    goToDetailOnClick (item: IAlgoliaUserModel) {
      this.$router.push(`/members/${item.id}`)
    },

    addNewPlace: function () {
      this.$router.push(`${this.$route.path}/new`)
    },

    onSearch: async function (): Promise<void> {
      await this.performPagination(true)
    },

    reloadUsers () {
      this.isLoading = true
      setTimeout(() => {
        this.performPagination(true)
      }, 3500)
      console.log('Users reloaded')
      this.isLoading = false
    },

    async onTableSort (fieldName: string, direction: string) {
      console.debug('Users - onTableSort', { fieldName, direction })
      this.sortField = fieldName
      this.sortDirection = direction
      await this.performPagination(true)
    },

    async onPaginateData ($state: any) {
      console.debug('AdminPlaces - onPaginateData')
      await this.performPagination()
      console.debug('AdminPlaces - onPaginateData', { end: this.usersPaginationEnd })

      if (this.usersPaginationEnd) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },

    async performPagination (reset?: boolean): Promise<void> {
      console.log('Search from users')
      const result = await this.paginateUsers(
        {
          forceReload: reset === true,
          query: this.searchQuery,
          facetFilters: [],
          sortField: this.sortField,
          sortDirection: this.sortDirection,
          hitsPerPage: 150
        }
      )

      // eslint-disable-next-line eqeqeq
      if (result != undefined && result.nbHits != undefined) {
        this.totalUsers = result.nbHits
      }
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      this.searchQuery = (this as any).$store.state.users.usersSearchQuery
      await this.performPagination(true)
    } catch (e) {
      console.error('error getting the membersSearchQuery')
      console.error(e)
    }
  }
})
