import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '@/views/LoginPage.vue'
import MembersPage from '@/views/MembersPage.vue'
import MemberDetailPage from '@/views/MemberDetailPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/members',
    name: 'members',
    meta: { requiresLogin: true },
    component: MembersPage
  },
  {
    path: '/members/:id',
    name: 'member',
    meta: { requiresLogin: true },
    component: MemberDetailPage
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/members' },
  { path: '/:pathMatch(.*)', name: 'bad-not-found', redirect: '/auth/login' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Verify login before resolving a path
router.beforeResolve(async (to, _, next) => {
  const store = await import('@/store') // await the store

  if (to.matched.some((record) => record.meta.requiresLogin)) {
    console.debug('router middleware - path requires login')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isLoggedIn = (store.default.getters as any)['auth/isLoggedIn'] // store with namespaced  modules

    if (isLoggedIn) {
      console.debug('router middleware - path requires login - user ok - continue')
      next() // proceed to the route
    } else {
      console.debug('router middleware - path requires login - user k0 - login')
      next('/auth/login') // redirect to login
    }
  } else {
    console.debug('router middleware - path doesn\'t require login')
    next() // proceed to the route
  }
})

// Save the last navigated page on path having the authentication
router.afterEach(async (to): Promise<void> => {
// console.log('afterEach', { to, from })

  const store = await import('@/store') // await the store
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  await (store as any).restored

  // console.log('afterEach - restored')

  if (to.matched.some((record) => record.meta.requiresLogin)) {
  // console.log('afterEach - restored - save')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store.default.dispatch('navigation/navigate', to.fullPath) // store with namespaced  modules
  }
})

export default router
