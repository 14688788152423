
import { IDocumentModel } from '@boxe-riviera-friulana/shared'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ModalDialog from './ModalDialog.vue'

const usersHelper = createNamespacedHelpers('users')

// TODO: Show the loader (based form the users Vuex Store)

export default defineComponent({
  name: 'UserDocumentDialog',

  emits: ['close', 'save'],

  components: { ModalDialog },

  props: {
    modelValue: { type: Object as () => IDocumentModel, required: true }
  },

  data: function () {
    return {
      newFile: undefined as string | undefined,
      loadedFileName: undefined as string | undefined,
      file: undefined as File | undefined,

      editingDocument: {
        description: ''
      } as Partial<IDocumentModel>
    }
  },

  computed: {
    loadedFileMimeTypeSlag: function () {
      // eslint-disable-next-line eqeqeq
      return this.file == undefined ? '' : (this.file as any).type
    },
    isNew: function () { return this.modelValue.id === 'new' }
  },

  methods: {
    ...usersHelper.mapActions(['updateUserDocument']),

    dropFile: function (event: any) {
      console.log('dropFile')
      event.preventDefault()

      // Ensure that you have a file before attempting to read it
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        const f = event.dataTransfer.files[0]

        // eslint-disable-next-line eqeqeq
        if (f.type != undefined) {
          this.getFileImage(event.dataTransfer.files[0])
        }
      }
    },
    onSelectFile: function () {
      (this.$refs.fileInput as any).click()
    },
    async onSave () {
      console.log('onSave')
      this.$emit('close')

      if (this.isNew || this.editingDocument.description !== this.modelValue.description) {
        await this.updateUserDocument({
          document: this.editingDocument,
          file: this.file,
          isNew: this.isNew
        })
      }

      this.$emit('save')
      this.$emit('close')
    },

    getFileImage: function (file: any) {
      console.log('getFileImage', { file })
      // create a new FileReader to read this image and convert to base64 format
      const reader = new FileReader()
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e: any) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData
        this.file = file
        this.editingDocument.title = file.name
        this.newFile = e.target.result
      }
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(file)
    },
    onFileInputChange: function (event: any) {
      console.log('onFileInputChange', { image: (event as any).target.files[0] })
      // Reference to the DOM input element
      const input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        this.getFileImage(input.files[0])
      }
    }
  },

  mounted () {
    this.editingDocument = { ...this.modelValue }
    if (this.editingDocument.documentUrl) {
      this.newFile = this.editingDocument.documentUrl
    }
  }
})
