
import MainNavigationBar from '@/components/MainNavigationBar.vue'
import CheckBox from '@/components/CheckBox.vue'
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import LoaderScreen from '@/components/LoaderScreen.vue'
import { promiseTimeout } from '@/utilities'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { AlertType } from '@/enums/alert-type'
const authHelper = createNamespacedHelpers('auth')

export default defineComponent({
  name: 'LoginPage',

  components: {
    MainNavigationBar, CheckBox, LoaderScreen
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      email: '',
      password: '',
      isLoading: false,
      errorBorder: '',

      randomImageUrl: undefined as string | undefined,
      passwordFieldType: 'password',
      logoImage: require('@/assets/images/logo.png')
    }
  },

  methods: {
    ...authHelper.mapActions(['emailSignIn']),
    seeHidePassword: function () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    performLogin () {
      this.$router.push('/members')
    },
    onEmailLogin: async function () {
      let result
      try {
        result = await this.emailSignIn({
          email: this.email,
          password: this.password
        })
      } catch (error) {
        console.error('Login - onEmailLogin - error', {
          error,
          email: this.email
        })
      }

      if (result) {
        // this.continueWithNavigation()
        this.$router.push('/members')
      }
    },
    async performLoginAction (): Promise<void> {
      this.isLoading = true
      await promiseTimeout(500)
      console.debug('performLogin')

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        console.error('Auth not performed due to validation error', { errors: this.v$.$errors })
        this.isLoading = false

        return
      }

      const result = await this.emailSignIn({ email: this.email, password: this.password })
      if (result === true) {
        // await this.$store.dispatch('alerts/create', { message: 'autenticazione riuscita', type: AlertType.success })
        console.debug('performLogin - ok')
        this.$router.push('/members')
      } else {
        console.error('auth error')
        this.errorBorder = 'border-red-400 border-2'
      }

      this.isLoading = false
    }
  },

  mounted () {
    this.randomImageUrl = `https://source.unsplash.com/random/${
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs as any).imageBox.clientWidth / 3 * 2
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }x${(this.$refs as any).imageBox.clientHeight}?boxing`
  },

  validations () {
    return {
      email: { required, email },
      password: { required }
    }
  }
})
